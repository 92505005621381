@import "../../responsiveMixins.scss";

// .logo {
//   overflow: hidden;
//   opacity: 0.99;
//   height: 100vh;
//   width: 100vw;
//   background: rgb(173, 177, 214);
//   background: linear-gradient(
//     27deg,
//     rgba(173, 177, 214, 1) 0%,
//     rgba(162, 105, 173, 1) 35%,
//     rgba(11, 35, 69, 1) 100%
//   );
//   position: absolute;
//   left: 0;
//   bottom: 0;
//   top: 0;
//   right: 0;
//   z-index: 999999;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;

//   svg {
//     animation: smoke 1900ms ease-in-out forwards;
//     animation-iteration-count: infinite;
//     max-height: 100px;
//     max-width: auto;
//     filter: brightness(0) invert(1);
//     @keyframes smoke {
//       0% {
//         transform: scale(1);
//       }
//       50% {
//         transform: scale(1.5);
//       }
//       100% {
//         transform: scale(1);
//       }
//     }
//   }
// }

.logo {
  opacity: 0.99;
  z-index: 999;
  height: 100vh;
  width: 100vw;
  position: absolute;
  background: rgb(173, 177, 214);
  background: linear-gradient(
    27deg,
    rgba(173, 177, 214, 1) 0%,
    rgba(162, 105, 173, 1) 35%,
    rgba(11, 35, 69, 1) 100%
  );
  top: 0;
  right: 0;
  z-index: 999999;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  svg {
    position: relative;
    animation: smoke 1400ms ease-in-out forwards;
    animation-iteration-count: infinite;
    max-height: 100px;
    max-width: auto;
    path {
      fill: #fff;
    }
    @keyframes smoke {
      0% {
        transform: scale(1);
      }
      50% {
        transform: scale(1.5);
      }
      100% {
        transform: scale(1);
      }
    }
  }
}
