@import "~react-image-gallery/styles/css/image-gallery.css";
@import "react-html5video/dist/styles.css";
/* @import url("https://fonts.googleapis.com/css2?family=Istok+Web:wght@400;700&family=Raleway:wght@200;300;400;500;600;700;800;900&family=Spectral:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,400&display=swap"); */

/* @font-face {
  font-family: "GramatikaBlack";
  src: local("Gramatika-Black"),
    url("./assets/fonts/Gramatika/Gramatika-Black.ttf") format("truetype");
}
@font-face {
  font-family: "GramatikaBlackItalic";
  src: local("Gramatika-BlackItalic"),
    url("./assets/fonts/Gramatika/Gramatika-BlackItalic.ttf") format("truetype");
}

@font-face {
  font-family: "GramatikaBold";
  src: local("Gramatika-Bold"),
    url("./assets/fonts/Gramatika/Gramatika-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "GramatikaBoldItalic";
  src: local("Gramatika-BoldItalic"),
    url("./assets/fonts/Gramatika/Gramatika-BoldItalic.ttf") format("truetype");
}

@font-face {
  font-family: "GramatikaItalic";
  src: local("Gramatika-Italic"),
    url("./assets/fonts/Gramatika/Gramatika-Italic.ttf") format("truetype");
}

@font-face {
  font-family: "GramatikaLight";
  src: local("Gramatika-Light"),
    url("./assets/fonts/Gramatika/Gramatika-Light.ttf") format("truetype");
}

@font-face {
  font-family: "GramatikaLightItalic";
  src: local("Gramatika-LightItalic"),
    url("./assets/fonts/Gramatika/Gramatika-LightItalic.ttf") format("truetype");
}

@font-face {
  font-family: "GramatikaMedium";
  src: local("Gramatika-Medium"),
    url("./assets/fonts/Gramatika/Gramatika-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "GramatikaMediumItalic";
  src: local("Gramatika-MediumItalic"),
    url("./assets/fonts/Gramatika/Gramatika-MediumItalic.ttf")
      format("truetype");
}

@font-face {
  font-family: "GramatikaRegular";
  src: local("Gramatika-Regular"),
    url("./assets/fonts/Gramatika/Gramatika-Regular.ttf") format("truetype");
} */

@font-face {
  font-family: "GramatikaBlack";
  src: local("Gramatika-Black"),
    url("./assets/fonts/woff/Gramatika/Gramatika-Black.woff") format("woff");
}
@font-face {
  font-family: "GramatikaBlackItalic";
  src: local("Gramatika-BlackItalic"),
    url("./assets/fonts/woff/Gramatika/Gramatika-BlackItalic.woff")
      format("woff");
}

@font-face {
  font-family: "GramatikaBold";
  src: local("Gramatika-Bold"),
    url("./assets/fonts/woff/Gramatika/Gramatika-Bold.woff") format("woff");
}

@font-face {
  font-family: "GramatikaBoldItalic";
  src: local("Gramatika-BoldItalic"),
    url("./assets/fonts/woff/Gramatika/Gramatika-BoldItalic.woff")
      format("woff");
}

@font-face {
  font-family: "GramatikaItalic";
  src: local("Gramatika-Italic"),
    url("./assets/fonts/woff/Gramatika/Gramatika-Italic.woff") format("woff");
}

@font-face {
  font-family: "GramatikaLight";
  src: local("Gramatika-Light"),
    url("./assets/fonts/woff/Gramatika/Gramatika-Light.woff") format("woff");
}

@font-face {
  font-family: "GramatikaLightItalic";
  src: local("Gramatika-LightItalic"),
    url("./assets/fonts/woff/Gramatika/Gramatika-LightItalic.woff")
      format("woff");
}

@font-face {
  font-family: "GramatikaMedium";
  src: local("Gramatika-Medium"),
    url("./assets/fonts/woff/Gramatika/Gramatika-Medium.woff") format("woff");
}

@font-face {
  font-family: "GramatikaMediumItalic";
  src: local("Gramatika-MediumItalic"),
    url("./assets/fonts/woff/Gramatika/Gramatika-MediumItalic.woff")
      format("truetype");
}

@font-face {
  font-family: "GramatikaRegular";
  src: local("Gramatika-Regular"),
    url("./assets/fonts/woff/Gramatika/Gramatika-Regular.woff") format("woff");
}

* {
  margin: 0;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: "GramatikaRegular", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
