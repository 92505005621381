@import "../../responsiveMixins.scss";

.cookiesDisclaimerWrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 60px;
  background-color: #190736;
  font-family: "GramatikaRegular", sans-serif;
  color: #fff;
  transition: transform 0.3s ease-in-out;
  transform: translateY(100px);
  z-index: 999;
  opacity: 0.95;
  &.visible {
    transform: translateY(0);
  }
  .cookiesDisclaimer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    width: 100%;
    height: 100%;
    max-width: 1200px;
    margin: 0 auto;
    @include xs {
      max-width: 290px;
      font-size: 9px;
    }
    @include sm {
      max-width: 350px;
      font-size: 9px;
    }
    @include md {
      max-width: 600px;
      font-size: 11px;
    }
    @include lg {
      max-width: 700px;
      font-size: 11px;
    }
    @include xl {
      max-width: 1000px;
    }
    a {
      color: #fff;
    }
    .accept {
      height: 25px;
      border: solid 1px #fff;
      border-radius: 2px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-transform: uppercase;
      line-height: 1;
      font-weight: 700;
      color: #fff;
      background-color: #190736;
      transition: all 0.2s ease-in-out;
      &:hover {
        background-color: #fff;
        color: #190736;
        cursor: pointer;
      }
    }
  }
}
