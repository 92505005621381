@import "../../responsiveMixins.scss";

.footerWrapper {
  width: 100%;
  height: 30px;
  // background-color: #253d7c;
  background-color: #190736;
  font-family: "GramatikaRegular", sans-serif;
  .footer {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-weight: 300;
    font-size: 12px;
    height: 30px;
    margin: 0 auto;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    @include xs {
      max-width: 290px;
      font-size: 9px;
    }
    @include sm {
      max-width: 350px;
      font-size: 9px;
    }
    @include md {
      max-width: 600px;
      font-size: 11px;
    }
    @include lg {
      max-width: 700px;
      font-size: 11px;
    }
    @include xl {
      max-width: 1000px;
    }
    p {
      a {
        color: #fff;
      }
    }
  }
}
