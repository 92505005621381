@import "./responsiveMixins.scss";

.appWrapper {
  width: 100%;
  height: 100%;
  .app {
    min-height: 100vh;
    margin: 0;
    display: grid;
    grid-template-rows: auto 1fr auto;
    .header {
      width: 100%;
      min-height: 50px;
    }
    .mainContentWrapper {
      width: 100%;
      .mainContent {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        width: 100%;
        max-width: 1200px;
        margin: 0 auto;
        padding: 20px 0;
        @include xs {
          max-width: 290px;
        }
        @include sm {
          max-width: 350px;
        }
        @include md {
          max-width: 600px;
        }
        @include lg {
          max-width: 700px;
        }
        @include xl {
          max-width: 1000px;
        }
      }
    }
    .footer {
      min-height: 30px;
      width: 100%;
    }
  }
}
