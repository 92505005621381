@import "../../responsiveMixins.scss";

.headerWrapper {
  width: 100%;
  min-height: 50px;
  background: transparent;
  position: relative;
  display: flex;
  z-index: 999;
  margin-top: 7px;
  .header {
    width: 100%;
    max-width: 1200px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    @include xs {
      max-width: 290px;
    }
    @include sm {
      max-width: 350px;
    }
    @include md {
      max-width: 600px;
    }
    @include lg {
      max-width: 700px;
    }
    @include xl {
      max-width: 1000px;
    }
    .left {
      display: flex;
      align-items: center;
      width: 30%;
      @include xs {
        width: 20%;
      }
      @include sm {
        width: 20%;
      }
      @include md {
        width: 25%;
      }
      a {
        text-decoration: none;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .logo {
          display: flex;
          align-items: center;
          svg {
            margin-right: 10px;
            max-height: 36px;
            max-width: 54px;
            path {
              fill: #fff;
            }
            @include xs {
              max-height: 22px;
              max-width: 33px;
            }
            @include sm {
              max-height: 22px;
              max-width: 33px;
            }
            @include md {
              max-height: 25px;
              max-width: 38px;
            }
          }
          &:hover {
            cursor: pointer;
          }
        }
        .title {
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          align-items: flex-start;
          .line1 {
            color: white;
            font-size: 17px;
            font-family: "GramatikaRegular", sans-serif;
            @include xs {
              display: none;
            }
            @include sm {
              display: none;
            }
            @include md {
              font-size: 10px;
            }
          }
          .line2 {
            color: white;
            font-size: 14px;
            font-family: "GramatikaRegular", sans-serif;
            @include xs {
              display: none;
            }
            @include sm {
              display: none;
            }
            @include md {
              font-size: 9px;
            }
          }
        }
      }
    }
    .rightWrapper {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      height: 100%;
      width: 35%;
      @include xs {
        width: 80%;
      }
      @include sm {
        width: 80%;
      }
      @include md {
        width: 70%;
      }
      @include lg {
        width: 60%;
      }
      @include xl {
        width: 45%;
      }
      .right {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        .toMain {
          display: flex;
          align-items: center;
          background: transparent;
          border: none;
          outline: none;
          text-decoration: none;
          &:hover {
            cursor: pointer;
          }
          svg {
            margin-left: 7px;
            max-height: 15px;
          }
          span {
            font-family: "GramatikaRegular", sans-serif;
            color: white;
            font-size: 16px;
            line-height: 16px;
            @include xs {
              font-size: 12px;
              line-height: 12px;
            }
          }
        }
        a {
          text-decoration: none;
          .addPost {
            width: 200px;
            border: 1px solid #fff;
            border-radius: 4px;
            padding: 3px 5px;
            transition: all 0.67s cubic-bezier(0.19, 1, 0.22, 1);
            display: flex;
            align-items: center;
            justify-content: space-around;
            @include xs {
              width: 110px;
            }
            @include sm {
              width: 140px;
            }
            @include md {
              margin-right: 20px;
              width: 150px;
            }
            svg {
              max-height: 15px;
              max-width: 15px;
              margin-right: 7px;
              path {
                fill: #fff;
              }
            }
            span {
              color: white;
              font-size: 13px;
              font-weight: 700;
              margin-top: 3px;
              text-transform: uppercase;
              font-family: "GramatikaBold", sans-serif;
              @include xs {
                font-size: 9px;
                line-height: 10px;
              }
              @include sm {
                font-size: 9px;
              }
              @include md {
                font-size: 9px;
              }
            }
            &:hover {
              cursor: pointer;
              background-color: #fff;
              svg {
                filter: none;
              }
              span {
                color: #253d7c;
              }
            }
          }
        }
        .user {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-left: 25px;
          width: 50%;
          @include xs {
            margin-left: 5px;
          }
          @include sm {
            margin-left: 10px;
          }
          @include md {
            margin-left: 0px;
          }
          .profile {
            width: 75px;
            @include xs {
              width: auto;
            }
            @include sm {
              width: auto;
            }
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0;
            text-decoration: none;
            display: flex;
            align-items: center;
            .avatarImg {
              height: 25px;
              width: 25px;
              border-radius: 50px;
              background-position: center center !important;
              background-size: contain !important;
              @include xs {
                display: none;
              }
              @include sm {
                display: none;
              }
            }
            span {
              color: white;
              margin-left: 5px;
              font-size: 16px;
              line-height: 16px;
              font-family: "GramatikaRegular", sans-serif;
              @include xs {
                font-size: 12px;
              }
              @include sm {
                font-size: 12px;
              }
            }
          }
          .settings {
            display: flex;
            align-items: center;
            svg {
              max-height: 25px;
              max-width: 25px;
              display: flex;
              align-items: center;
              path {
                fill: #fff;
              }
              @include sm {
                max-height: 20px !important;
              }
            }
          }
          .logout {
            display: flex;
            align-items: center;
            border: none;
            outline: none;
            background: transparent;
            &:hover {
              cursor: pointer;
            }
            svg {
              max-height: 25px;
              path {
                fill: #fff;
              }
              @include sm {
                max-height: 20px !important;
              }
            }
          }
          .logout {
            svg {
              max-width: 25px;
              max-height: 25px;
            }
          }
        }
      }
    }
  }
}
